import React, { useState, useEffect } from 'react'
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import firebase from "../../Config/firebase"
import { CgArrowLongRight } from 'react-icons/cg';
import "../../StyleSheets/Pages/BlogPage.css"
import BlogImage from "../../Images/BlogImage1.jpg"
import BlogImage3 from "../../Images/BlogImage3.jpg"
import FBIcon1 from "../../Images/FBIcon1.jpg"
import FBIcon2 from "../../Images/FBIcon2.jpg"

export default function BlogPage(props) {


       const [showblogs, setShowblogs] = useState({
              showBlogList: [],
       })
       useEffect(() => {
              var blogs = [];
              firebase.database().ref(`blog/${props.match.params.index}`).on("value", getData => {
                     let data = getData.val();
                     let keys = Object.keys(data);
                     keys.forEach((key) => {
                            blogs[key] = data[key];
                     });
              });
              setShowblogs({ showBlogList: blogs })
       }, [])

       console.log()
       return (
              <>
                     <Header />

                     {/* Page   Indicator   */}


                     <div className="TopUpBlog">
                            <div className="TopUpHeading">
                                   <h1>{showblogs.showBlogList.name}</h1>
                            </div>
                            <div className="IndicatoeBtnBlog">
                                   <p>Home</p>
                                   <CgArrowLongRight className="rightArrow" />
                                   <p>{showblogs.showBlogList.serviceName}</p>
                                   <CgArrowLongRight className="rightArrow" />
                                   <p>{showblogs.showBlogList.name}</p>
                            </div>
                     </div>

                     {/* Page   Content   */}
                     <div className="fullBlog">
                            <div className="fullBlogContent">
                                   <div className="fullBlogLeft col-sm-12 col-md-8 col-lg-8">
                                          <div className="blogImgContainer">
                                                 <img src={showblogs.showBlogList.image} alt="" />
                                          </div>
                                          <div className="blogText">
                                                 <p>{showblogs.showBlogList.description}</p>
                                          </div>
                                   </div>
                                   <div className="fullBlogRight col-sm-12 col-md-4 col-lg-4">
                                          <div className="blogPost">
                                                 <div className="FBP">
                                                        <div className="footerblogImg">
                                                               <img src={FBIcon1} alt="" />
                                                        </div>
                                                        <div className="footerblogRT">
                                                               <div className="blogdate">
                                                                      <p>June 25, 2021</p>
                                                               </div>
                                                               <div className="TP">
                                                                      <p>Things To Consider When Hiring Book Cover Design Services</p>
                                                               </div>
                                                        </div>
                                                 </div>

                                                 <div className="FBP">
                                                        <div className="footerblogImg">
                                                               <img src={FBIcon2} alt="" />
                                                        </div>
                                                        <div className="footerblogRT">
                                                               <div className="blogdate">
                                                                      <p>June 20, 20201</p>
                                                               </div>
                                                               <div className="TP">
                                                                      <p>How Book Publishing Services Works: 5 Steps For Newbie Authors</p>
                                                               </div>
                                                        </div>
                                                 </div>
                                                 <div className="FBP">
                                                        <div className="footerblogImg">
                                                               <img src={BlogImage3} alt="" />
                                                        </div>
                                                        <div className="footerblogRT">
                                                               <div className="blogdate">
                                                                      <p>June 15, 2021</p>
                                                               </div>
                                                               <div className="TP">
                                                                      <p>Tips To Boost Your eBook Sales</p>
                                                               </div>
                                                        </div>
                                                 </div>
                                          </div>
                                   </div>
                            </div>
                     </div>
                     <Footer />

              </>
       )
}
