import React, { useState, useEffect } from 'react'
import Header from "../../Header/Header"
import Footer from "../../Footer/Footer"
import RecentCollection from "../../RecentCollection/RecentCollection"
import ContactForm from "../../ContactForm/ContactForm"
import OurProcess from "../../OurProcess/Process"
import "../../../StyleSheets/Pages/Services/BookCover.css"

import BrandImg1 from "../../../Images/BrandImg1.png"
import BrandImg2 from "../../../Images/BrandImg2.png"
import BrandImg3 from "../../../Images/BrandImg3.png"
import BrandImg4 from "../../../Images/BrandImg4.png"

import BigBrandImg1 from "../../../Images/BigBrandImg1.png"
import BigBrandImg2 from "../../../Images/BigBrandImg2.png"
import BigBrandImg3 from "../../../Images/BigBrandImg3.png"
import BigBrandImg4 from "../../../Images/BigBrandImg4.png"
import BigBrandImg5 from "../../../Images/BigBrandImg5.png"

import bookCoverDesign from "../../../Images/book-cover-design.png"
import Margaret from "../../../Images/margaret.png"
import Data from "../../../assets/BookCover.json"
import firebase from "../../../Config/firebase"

function ServicePageDemo(props) {


       const [Show_servicePage, setShow_servicePage] = useState("")

       useEffect(() => {
              var ser = {};
              firebase.database().ref(`service/${props.match.params.index}`).on("value", SSerPage => {
                     let data = SSerPage.val();
                     let keys = Object.keys(data);
                     keys.forEach((key) => {
                            ser[key] = data[key];
                     });
                     setShow_servicePage(ser)
              })
       }, [])

       return (
              <>
                     <Header />
                     <div className="BookWritingSlider">
                            <div className="BookWritingSliderContent container">
                                   <div className="row">
                                          <div className="BookWritingTextSide col-sm-6 col-md-7">
                                                 <div className="BookWritingtextContent">
                                                        <div className="BookWritingtag">
                                                               <p>{Show_servicePage.tagname}</p>
                                                        </div>
                                                        <div className="BookWritingHeadingLine">
                                                               <h1>{Show_servicePage.mainHeading}</h1>
                                                        </div>
                                                        <div className="BookWritingParagraphLine">
                                                               <p>{Show_servicePage.description}</p>
                                                        </div>

                                                        <div className="ButtonsLine">
                                                               <button>Get a Quote</button>
                                                               <button>Live Chat</button>
                                                        </div>
                                                        <div className="brandLogo">
                                                               <div className="brand col">
                                                                      <img src={BrandImg1} className="brandLogoImg img-fluid" pt-0 alt="" />
                                                               </div>
                                                               <div className="brand col">
                                                                      <img src={BrandImg2} className="brandLogoImg img-fluid" pt-0 alt="" />
                                                               </div>
                                                               <div className="brand col">
                                                                      <img src={BrandImg3} className="brandLogoImg img-fluid" pt-0 alt="" />
                                                               </div>
                                                               <div className="brand col">
                                                                      <img src={BrandImg4} className="brandLogoImg img-fluid" alt="" />
                                                               </div>
                                                        </div>
                                                 </div>
                                          </div>
                                          <div className="formSide mt-0 mt-sm-5 col-sm-6 col-md-5">
                                                 <div className="row">
                                                        <div className="formContainer pb-0 pb-sm-5">
                                                               <div className="formHeader mt-3 mb-3 mt-sm-0">
                                                                      <h2>Sign Up Now</h2>
                                                                      <h4>GET UP TO <span>70%</span> OFF</h4>
                                                               </div>
                                                               <div className="formContent formBody p-4">
                                                                      <div className="Datainputs m-sm-4">
                                                                             <input type="text" className="infoInputs col-6" placeholder="First Name" />
                                                                             <input type="text" className="infoInputs col-6" placeholder="Last Name " />
                                                                             <input type="text" className="infoInputs col-6" placeholder="Contact Number" />
                                                                             <input type="text" className="infoInputs col-6" placeholder="Email Address " />
                                                                             <input type="text" className="subject" placeholder="Subject" />
                                                                             <textarea className="commentArea" cols="30" rows="6" placeholder="Your message"></textarea>
                                                                             <button>Send Message</button>
                                                                      </div>
                                                               </div>

                                                        </div>
                                                 </div>
                                          </div>
                                   </div>
                            </div>
                            <div className="Branding bg-white">
                                   <div className="brandingContent">
                                          <div className="col-6 col-sm text-center bigBrands">
                                                 <img src={BigBrandImg1} className="img-fluid" alt="" />
                                          </div>
                                          <div className="col-6 col-sm text-center bigBrands">
                                                 <img src={BigBrandImg2} className="img-fluid" alt="" />
                                          </div>
                                          <div className="col-6 col-sm text-center bigBrands">
                                                 <img src={BigBrandImg3} className="img-fluid" alt="" />
                                          </div>
                                          <div className="col-6 col-sm text-center bigBrands">
                                                 <img src={BigBrandImg4} className="img-fluid" alt="" />
                                          </div>
                                          <div className="col-6 col-sm text-center bigBrands">
                                                 <img src={BigBrandImg5} className="img-fluid" alt="" />
                                          </div>
                                   </div>
                            </div>
                     </div >



                     <div className="secSlider">
                            <div className="secSliderContent container">
                                   <div className="row">
                                          <div className="secLeft col-sm-6">
                                                 <h1>{Show_servicePage.secHeading}</h1>
                                                 <p>{Show_servicePage.secDescription}</p>
                                                 <div className="secBtn">
                                                        <div className="row">
                                                               <div className="col-6">
                                                                      <button className="w-100">Get a Quote</button>
                                                               </div>
                                                               <div className="col-6">
                                                                      <button className="w-100">Live Chat</button>
                                                               </div>
                                                        </div>
                                                 </div>
                                          </div>
                                          <div className="secRight col-sm-6">
                                                 <img className="img-fluid" src={Show_servicePage.secImage} alt={Show_servicePage.secHeading} />
                                          </div>
                                   </div>
                            </div>
                     </div>


                     <RecentCollection />


                     <div className="BlueSlide">
                            <div className="container">
                                   <div className="row">
                                          <div className="blueSlideText col-sm-6 col-lg-8 pb-0 pb-sm-5">
                                                 <h1>Looking for an engaging Cover for your Eloquent Book?</h1>
                                                 <p>We are honored to provide you top-notch quality designs to make sure that your trust is gained for a lifetime.</p>
                                          </div>
                                          <div className="blueSlideBtn mt-0 mt-sm-5 mb-4 col-sm-6 col-lg-4">
                                                 <div className="row">
                                                        <div className="col-6 col-sm-12">
                                                               <button className="w-100">Get a Quote</button>
                                                        </div>
                                                        <div className=" pt-sm-5 pt-md-3 col-6 col-sm-12">
                                                               <button className="w-100">Live Chat</button>
                                                        </div>
                                                 </div>
                                          </div>
                                   </div>
                            </div>
                     </div>
                     <div className="ProcessHeading">
                            <h1>Our Process</h1>
                     </div>
                     <OurProcess />


                     <div className="margaretSlide">
                            <div className="container">
                                   <div className="row">
                                          <div className="col-sm-6 col-md-9">
                                          <div className="margaretSlideText pb-0 pl-3 pr-3 p-sm-5">
                                                 <p><span>Margaret Atwood said,</span> "You're never going to kill storytelling because it's built-in the human plan. We come with it."</p>
                                                 <p>So, hurry up and tell your story to the world through us!</p>
                                          </div>
                                                 <div className="row pb-5">
                                                        <div className="col-6">
                                                               <button className="w-100">Get a Quote</button>
                                                        </div>
                                                        <div className="col-6">
                                                               <button className="w-100">Live Chat</button>
                                                        </div>
                                                 </div>
                                          </div>
                                          <div className="margaretSlideImg col-sm-6 col-md-3 text-center">
                                                 <img src={Margaret} alt="" className="img-fluid" />
                                          </div>
                                   </div>
                            </div>
                     </div>
                     <ContactForm />
                     <Footer />
              </>
       )
}

export default ServicePageDemo;
