import React from 'react'
import "../Styles/Dashboard.css"
import { Link } from "react-router-dom";
import Header from "../Common/Header.jsx"
import { BsChevronDown } from 'react-icons/bs';
import { SiMicroDotBlog } from 'react-icons/si';
import { FaComments } from 'react-icons/fa';
import { AiFillHome } from 'react-icons/ai';
import { RiUserSettingsFill } from 'react-icons/ri';


function Dashboard() {

       return (
              <>
                     <Header />
                     <div className="DashboardContent">
                            <div className="SideMenu">
                                   <ul>
                                          <p>Navigation</p>
                                          <li>
                                                 <Link className="adminNav" to="/admin/dashboard"><AiFillHome className="menuListIcons" />Home</Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/services">
                                                        <RiUserSettingsFill className="menuListIcons" />Services
                                                 </Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/blogs"><SiMicroDotBlog className="menuListIcons" />Blog</Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/testimonials"><FaComments className="menuListIcons" />Testimonials</Link>
                                          </li>
                                   </ul>
                            </div>
                            <div className="dashboardpage">
                                   <div className="dashboardPageContent">
                                          <h1 className="topHeading">Dashboard</h1>
                                   </div>
                            </div>
                     </div>
              </>
       )
}

export default Dashboard