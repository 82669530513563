import firebase from 'firebase';
import 'firebase/database'


/////////////////  Account configuration


var firebaseConfig = {
       apiKey: "AIzaSyDBKhuQG2buJmgg2p7fjxDwuod2s3QjIPc",
       authDomain: "book-marketers.firebaseapp.com",
       projectId: "book-marketers",
       storageBucket: "book-marketers.appspot.com",
       messagingSenderId: "997353411239",
       appId: "1:997353411239:web:daf8b8851b1560f9420182",
       measurementId: "G-VR9LZ3WGHQ"
};


// Initialize Firebase

firebase.initializeApp(firebaseConfig);
const storage = firebase.storage()

export { storage, firebase as default }