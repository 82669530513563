import React from 'react'
import AnimatedVideo2 from "../../Videos/Animated_Video_2.m4v"
import "../../StyleSheets/HiringSolution/HireSolution.css"

function HireSolution() {
       return (
              <div className='HiringSolution'>
                     <div className="HiringContent container">
                            <div className="LeftText text-center text-sm-left col-sm-6">
                                   <h1>Are you looking for hiring a writer?</h1>
                                   <p>Our skilled team of writers is always available, to turn your idea into an incredible book. Give us the chance to help your idea reach an audience around the globe. You’ll find us experienced and secured to work with because, at Dunesoft, we pour our hearts out in our work.</p>
                                   <button>Get a Quote</button>
                                   <button>Live Chat</button>
                            </div>
                            <video
                                   autoPlay
                                   loop
                                   muted
                                   style={{
                                          padding: "20px 50px",
                                          margin: "0",
                                   }}
                                   className="col-sm-6"
                            >
                                   <source src={AnimatedVideo2} type="video/mp4" />
                            </video>
                     </div>
              </div>
       )
}

export default HireSolution
