import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import Header from "../../Common/Header.jsx"
import { SiMicroDotBlog } from 'react-icons/si';
import { FaComments } from 'react-icons/fa';
import { AiFillHome } from 'react-icons/ai';
import { RiUserSettingsFill } from 'react-icons/ri';
import "../../Styles/ServiceStyling/AddService.css"
import firebase from "../../../Config/firebase"
import { storage } from "../../../Config/firebase"

function EditServicePage(props) {
       ////////////////////////---------------------Retreiving Data To Firebase Database----------------------////////////////////////

       const [E_servcie, setE_Service] = useState("")

       useEffect(() => {
              var service = {};
              firebase.database().ref(`service/${props.match.params.index}`).on("value", eServcie => {
                     let data = eServcie.val();
                     let keys = Object.keys(data);
                     keys.forEach((key) => {
                            service[key] = data[key];
                     });
                     setE_Service(service)
              })
       }, [])

       ////////////////////////---------------------Getting Data In States From Input Fields----------------------////////////////////////


       ////////////////////////---------------------Saving Data In States----------------------////////////////////////

       const [e_tagName, setE_TagName] = useState("")
       const [e_mainHeading, setE_MainHeading] = useState("")
       const [e_description, setE_Description] = useState("")

       const [e_secHeading, setE_SecHeading] = useState("")
       const [e_secDescription, setE_SecDescription] = useState("")
       const [e_secImage, setE_SecImage] = useState(null)

       const [e_pageName, setE_PageName] = useState("")
       const [e_pageURL, setE_PageURL] = useState("")

       ////////////////////////---------------------Getting Data From Input Fields For First Slider----------------------////////////////////////


       const TagHandle = (e) => {
              setE_TagName(e.target.value)
       }

       const HeadingHandle = (e) => {
              setE_MainHeading(e.target.value)
       }

       const DescriptionHandle = (e) => {
              setE_Description(e.target.value)
       }

       ////////////////////////---------------------Getting Data From Input Fields For Second Slider----------------------////////////////////////

       const SecSlideHeading = (e) => {
              setE_SecHeading(e.target.value)
       }

       const SecDescription = (e) => {
              setE_SecDescription(e.target.value)
       }

       const SecSlideImage = (e) => {
              if (e.target.files[0]) {
                     setE_SecImage(e.target.files[0])
              }
       }

       ////////////////////////---------------------Getting Data From Input Fields For Page Info----------------------////////////////////////

       const pAgeName = (e) => {
              setE_PageName(e.target.value)
       }

       const pAgeURL = (e) => {
              setE_PageURL(e.target.value)
       }


       ////////////////////////---------------------Updated Data Upload To Firebase Database----------------------////////////////////////


       var serviceImgURL = '';

       const updateFunc = async () => {
              if (e_secImage == null) {
                     alert("Please Select an Image")
              }
              else {
                     const uploadtask = storage.ref(`serviceImage/${e_secImage.name}`).put(e_secImage);
                     uploadtask.on(
                            "state_changed",
                            // snapshot = {}, 
                            error => {
                                   console.log(error)
                            },
                            () => {
                                   storage
                                          .ref("serviceImage")
                                          .child(e_secImage.name)
                                          .getDownloadURL()
                                          .then(url => {
                                                 serviceImgURL = url
                                          })
                            }
                     )
                     uploadtask.snapshot.ref.getDownloadURL().then((url) => {
                            var imgUrl = url
                            firebase.database().ref(`service/${props.match.params.index}`).set({
                                   tagname: e_tagName,
                                   mainHeading: e_mainHeading,
                                   description: e_description,
                                   secHeading: e_secHeading,
                                   secDescription: e_secDescription,
                                   secImage: imgUrl,
                                   pageName: e_pageName,
                                   pageURL: e_pageURL,
                            })
                            alert("Succesfully Uploaded")
                     })
              }

       }
       return (
              <>
                     <Header />
                     <div className={`ServiceContent ${props.match.params.index}`} >
                            <div className="SideMenu">
                                   <ul>
                                          <p>Navigation</p>
                                          <li>
                                                 <Link className="adminNav" to="/admin/dashboard"><AiFillHome className="menuListIcons" />Home</Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/services">
                                                        <RiUserSettingsFill className="menuListIcons" />Services
                                                 </Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/blogs"><SiMicroDotBlog className="menuListIcons" />Blog</Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/testimonials"><FaComments className="menuListIcons" />Testimonials</Link>
                                          </li>
                                   </ul>
                            </div>
                            <div className="ServicePage">
                                   <h1 className="topHeading">Edit Service </h1>
                                   <div className="servicePageContent">
                                          <div className="inputFields MSIF col-md-6"><br />
                                                 <h1>Main Silder</h1>
                                                 <label className="label">Tag Name :</label><input type="text" onClick={TagHandle} defaultValue={E_servcie.tagname} placeholder="Enter page tag name" className="servicefields" /><br />
                                                 <label className="label">Main Heading :</label><input type="text" onClick={HeadingHandle} defaultValue={E_servcie.mainHeading} placeholder="Enter heading" className="servicefields" /><br />
                                                 <div className="descriptionContainer">
                                                        <label className="label">Description :</label>
                                                        {/* <textarea type="text" id="content_additional_notes" name="additional_notes" className="form-control main_content servicefields " placeholder=""></textarea> */}
                                                        <textarea name="" placeholder="Enter description" onClick={DescriptionHandle} defaultValue={E_servcie.description} className="servicefields" id="" cols="20" rows="5"></textarea><br />
                                                 </div>
                                          </div>
                                          <div className="inputFields SSIF col-md-6"><br />
                                                 <h1>Second Silder</h1>
                                                 <label className="label">Heading :</label><input type="text" onClick={SecSlideHeading} defaultValue={E_servcie.secHeading} placeholder="Enter slider heading" className="servicefields" /><br />
                                                 <div className="descriptionContainer">
                                                        <label className="label">Description :</label><textarea name="" onClick={SecDescription} defaultValue={E_servcie.secDescription} placeholder="Enter slider description" className="servicefields" id="" cols="20" rows="5"></textarea><br />
                                                 </div>
                                                 <label className="label">Slider Image :</label><input type="file" onClick={SecSlideImage} /><br />
                                          </div>
                                          <div className="inputFields TSIF col-md-6"><br />
                                                 <h1>Page Information</h1>
                                                 <label className="label">Page Name :</label><input type="text" onClick={pAgeName} defaultValue={E_servcie.pageName} placeholder="Enter page name" className="servicefields" /><br />
                                                 <label className="label">Page Url :</label><input type="text" onClick={pAgeURL} defaultValue={E_servcie.pageURL} placeholder="Enter page URL" className="servicefields" /><br />
                                                 <button className="ServiceUpload" onClick={updateFunc}>Update</button>
                                          </div>
                                   </div>
                            </div>
                     </div>
              </>
       )
}

export default EditServicePage
