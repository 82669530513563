import React from 'react'
import "../../StyleSheets/Pages/About.css"
import AboutImg from "../../Images/about-us.png"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import RecentCollection from "../RecentCollection/RecentCollection"
import ContactForm from "../ContactForm/ContactForm"
import { CgArrowLongRight } from 'react-icons/cg';
import Data from '../../assets/about.json'
function About() {

       return (

              <div className="AboutPage">
                     <Header />
                     <div className="TopUp">
                            <div className="TopUpHeading">
                                   <h1>About Us</h1>
                            </div>
                            <div className="IndicatoeBtn">
                                   <p>Home</p>

                                   <CgArrowLongRight className="rightArrow" />

                                   <p>About us</p>
                            </div>
                     </div>

                     {/* About Content */}

                     <div className="Aboutcontent">
                            <div className="content">
                                   <div className="upPartition">
                                          <div className="leftTextSide col-sm-12 col-md-5 col-lg-6">
                                                 <h1>{Data.aboutHeading1}</h1>
                                                 <p>{Data.aboutPara1}</p>
                                                 <h1>{Data.aboutHeading2}</h1>
                                                 <p>{Data.aboutPara2}</p>
                                                 <h1>{Data.aboutHeading3}</h1>
                                                 <p>{Data.aboutPara3}</p>

                                          </div>
                                          <div className="rightImageSide col-sm-12 col-md-5 col-lg-6">
                                                 <img src={AboutImg} alt="" />
                                          </div>
                                   </div>
                                   <div className="downPartition">
                                          <div className="downLeft">
                                                 <h1>{Data.aboutHeading4}</h1>
                                                 <p>{Data.aboutPara4}</p>
                                          </div>
                                          <div className="downRight">
                                                 <h1>{Data.aboutHeading5}</h1>
                                                 <p>{Data.aboutPara5}</p>
                                          </div>
                                   </div>
                            </div>
                     </div>
                     <RecentCollection />
                     <ContactForm />
                     <Footer />
              </div>
       )
}

export default About;
