import React, { useState } from 'react'
import "../Styles/Testimonials.css"
import { Link } from "react-router-dom";
import Header from "../Common/Header.jsx"
import { BsChevronDown } from 'react-icons/bs';
import { SiMicroDotBlog } from 'react-icons/si';
import { FaComments } from 'react-icons/fa';
import { AiFillHome } from 'react-icons/ai';
import { storage } from "../../Config/firebase"
import { RiUserSettingsFill } from 'react-icons/ri';
import firebase from "../../Config/firebase"
// import Calendar from "react-calendar"

function AddTestimonials() {
       ////////////////////////---------------------Saving Data In States----------------------////////////////////////


       const [testimonialName, setTestimonialName] = useState()
       const [testimonialReview, setTestimonialReview] = useState()
       const [testimonialImge, setTestimonialImge] = useState(null)

       ////////////////////////---------------------Getting Data From Input Fields For Testimonials----------------------////////////////////////

       const TestimonialsName = (e) => {
              setTestimonialName(e.target.value)
       }

       const TestimonialsDes = (e) => {
              setTestimonialReview(e.target.value)
       }

       const TestimonialsImage = (e) => {
              if (e.target.files[0]) {
                     setTestimonialImge(e.target.files[0])
              }
       }

       ////////////////////////---------------------Uploading Data On Firebase----------------------////////////////////////

       var testimonialImgURL = '';

       const upload = async () => {
              if (testimonialImge == null) {
                     alert("Please Select an Image")
              }
              else {
                     const uploadtask = storage.ref(`testimonialprofileImages/${testimonialImge.name}`).put(testimonialImge);
                     uploadtask.on(
                            "state_changed",
                            // snapshot = {}, 
                            error => {
                                   console.log(error)
                            },
                            () => {
                                   storage
                                          .ref("testimonialprofileImages")
                                          .child(testimonialImge.name)
                                          .getDownloadURL()
                                          .then(url => {
                                                 testimonialImgURL = url
                                          })
                            }
                     )
                     uploadtask.snapshot.ref.getDownloadURL().then((url) => {
                            var imgUrl = url

                            firebase.database().ref("testimonials").push({
                                   tesName: testimonialName,
                                   tesReview: testimonialReview,
                                   personImage: imgUrl,
                            })

                            alert("Succesfully Uploaded")
                     })
              }

       }

       return (
              <>
                     <Header />
                     <div className="TestimonialContent">
                            <div className="SideMenu">
                                   <ul>
                                          <p>Navigation</p>
                                          <li>
                                                 <Link className="adminNav" to="/admin/dashboard"><AiFillHome className="menuListIcons" />Home</Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/services">
                                                        <RiUserSettingsFill className="menuListIcons" />Services
                                                 </Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/blogs"><SiMicroDotBlog className="menuListIcons" />Blog</Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/testimonials"><FaComments className="menuListIcons" />Testimonials</Link>
                                          </li>
                                   </ul>
                            </div>
                            <div className="Testimonialpage">
                                   <div className="TestimonialPageContent">
                                          <h1 className="topHeading">Add Testimonials</h1>
                                          <div className="inputFields TSIF col-md-6"><br />
                                                 <label className="label"> Name :</label><input type="text" onChange={TestimonialsName} placeholder="Enter name" className="servicefields" /><br />
                                                 <div className="descriptionContainer">
                                                        <label className="label">Review :</label><textarea name="" onChange={TestimonialsDes} placeholder="Enter review description" className="servicefields" id="" cols="20" rows="5"></textarea><br />
                                                 </div>
                                                 < label className="label">Image :</label><input type="file" onChange={TestimonialsImage} /><br />
                                                 <button className="ServiceUpload" onClick={upload}>Add Testimonial</button>
                                          </div>
                                   </div>
                            </div>
                     </div>
              </>
       )
}

export default AddTestimonials
