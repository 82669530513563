import React from 'react'
import Footer from "../../Footer/Footer"
import Header from "../../Header/Header"
import RecentCollection from "../../RecentCollection/RecentCollection"
import ContactForm from "../../ContactForm/ContactForm"

import BrandImg1 from "../../../Images/BrandImg1.png"
import BrandImg2 from "../../../Images/BrandImg2.png"
import BrandImg3 from "../../../Images/BrandImg3.png"
import BrandImg4 from "../../../Images/BrandImg4.png"

import BigBrandImg1 from "../../../Images/BigBrandImg1.png"
import BigBrandImg2 from "../../../Images/BigBrandImg2.png"
import BigBrandImg3 from "../../../Images/BigBrandImg3.png"
import BigBrandImg4 from "../../../Images/BigBrandImg4.png"
import BigBrandImg5 from "../../../Images/BigBrandImg5.png"

import servicePageIcon1 from "../../../Images/servicePageIcon1.svg"
import servicePageIcon2 from "../../../Images/servicePageIcon2.svg"
import servicePageIcon6 from "../../../Images/servicePageIcon6.svg"
import servicePageIcon7 from "../../../Images/servicePageIcon7.svg"

import bookMrketing from "../../../Images/bookMrketing.png"
import Margaret from "../../../Images/margaret.png"

import Data from "../../../assets/BookMarketing.json"

function BookMarketing() {
       return (
              <>
                     <Header />
                     <div className="BookWritingSlider">
                            <div className="BookWritingSliderContent">
                                   <div className="BookWritingTextSide">
                                          <div className="BookWritingtextContent">
                                                 <div className="BookWritingtag">
                                                        <p>Making your Words Available everywhere</p>
                                                 </div>
                                                 <div className="BookWritingHeadingLine">
                                                        <h1>{Data.mainHeading}</h1>
                                                 </div>
                                                 <div className="BookWritingParagraphLine">
                                                        <p>{Data.mainPara}</p>
                                                 </div>

                                                 <div className="ButtonsLine">
                                                        <button>Get a Quote</button>
                                                        <button>Live Chat</button>
                                                 </div>
                                                 <div className="brandLogo">
                                                        <div className="brand">
                                                               <img src={BrandImg1} className="brandLogoImg" alt="" />
                                                        </div>
                                                        <div className="brand">
                                                               <img src={BrandImg2} className="brandLogoImg" alt="" />
                                                        </div>
                                                        <div className="brand">
                                                               <img src={BrandImg3} className="brandLogoImg" alt="" />
                                                        </div>
                                                        <div className="brand">
                                                               <img src={BrandImg4} className="brandLogoImg" alt="" />
                                                        </div>
                                                 </div>
                                          </div>
                                   </div>
                                   <div className="formSide">
                                          <div className="formContainer">
                                                 <div className="formHeader">
                                                        <h2>Sign Up Now</h2>
                                                        <h4>GET UP TO <span>70%</span> OFF</h4>
                                                 </div>
                                                 <div className="formBody">
                                                        <div className="infiInputs">
                                                               <input className="sliderForm" type="text" placeholder="First Name" />
                                                               <input className="sliderForm" type="text" placeholder="Last Name" />
                                                               <input className="sliderForm" type="text" placeholder="Email Address" />
                                                               <input className="sliderForm" type="text" placeholder="Phone Number" />
                                                               <input className="subjectInput" type="text" placeholder="Subject" />
                                                               <textarea className="textAreaInput" cols="50" rows="8" placeholder="Your Message"></textarea>
                                                               <button>Send Message</button>
                                                        </div>
                                                 </div>
                                          </div>
                                   </div>
                            </div>
                            <div className="Branding">
                                   <div className="brandingContent">
                                          <div className="bigBrands">
                                                 <img src={BigBrandImg1} className="bigBrandLogoImg" alt="" />
                                          </div>
                                          <div className="bigBrands">
                                                 <img src={BigBrandImg2} className="bigBrandLogoImg" alt="" />
                                          </div>
                                          <div className="bigBrands">
                                                 <img src={BigBrandImg3} className="bigBrandLogoImg" alt="" />
                                          </div>
                                          <div className="bigBrands">
                                                 <img src={BigBrandImg4} className="bigBrandLogoImg" alt="" />
                                          </div>
                                          <div className="bigBrands">
                                                 <img src={BigBrandImg5} className="bigBrandLogoImg" alt="" />
                                          </div>
                                   </div>
                            </div>
                     </div >




                     <div className="secSlider">
                            <div className="secSliderContent">
                                   <div className="secLeft">
                                          <h1>{Data.secSlideHeading}</h1>
                                          <p>{Data.secSlidePara}</p>
                                          <div className="secBtn">
                                                 <button>Get a Quote</button>
                                                 <button>Live Chat</button>
                                          </div>
                                   </div>
                                   <div className="secRight">
                                          <img src={bookMrketing} alt="" />
                                   </div>
                            </div>
                     </div>


                     <div className="BlueSlide">
                            <div className="blueSlideText">
                                   <h1>We have a Secret Recipe for providing you a Smooth E-Marketing Solution for your book</h1>
                                   <p>We are honored to provide you top-notch quality content to make sure that your trust is gained for a lifetime.</p>
                            </div>
                            <div className="blueSlideBtn">
                                   <button>Get a Quote</button><br />
                                   <button>Live Chat</button>
                            </div>
                     </div>




                     {/* The ProofReading and Editorial Process */}

                     <div className="ProcessHeading">
                            <h1>Our Process</h1>
                     </div>

                     <div className="Process">
                            <div className="processContent1">
                                   <div className="firstProcess">
                                          <div className="head">
                                                 <img src={servicePageIcon1} alt="" />
                                                 <h4>Experienced Marketers</h4>
                                          </div>
                                          <p>Our book marketing team is made up of highly skilled marketers from various industries with the skills to develop the most powerful and effective campaign. By implementing impactful strategies to let you enjoy premium returns within the budget, they concentrate on every aspect of digital marketing.</p>
                                   </div>
                                   <div className="secondProcess">
                                          <div className="head2">
                                                 <img src={servicePageIcon2} alt="" />
                                                 <h4>Personalized Services</h4>
                                          </div>
                                          <p>It doesn't matter whether you want our PPC, SEO, or social media marketing services, as our primary concern remains your requirements and specifications, and we feel it is our responsibility to give you the best solution for your business needs. We delegate all of our projects to an expert who has previous experience working on similar projects and knows the project's ins and outs.</p>
                                   </div>
                            </div>
                            <div className="processContent2">
                                   <div className="ProcessData">
                                          <div className="process3">
                                                 <div className="heading">
                                                        <img src={servicePageIcon6} alt="" />
                                                        <h4>Designing your Masterpiece</h4>
                                                 </div>
                                                 <p>To publish your masterpiece, our multitalented designers get their hands on in coming up appealing front and back covers, disclaimers, Author's biography, and table of contents.</p>
                                          </div>
                                          <div className="process4">
                                                 <div className="heading">
                                                        <img src={servicePageIcon7} alt="" />
                                                        <h4>Publishing and Distribution Process</h4>
                                                 </div>
                                                 <p>In the final part of the publishing process, we obtain your ISBN Codes to get your printing started. After our influential clients have approved their manuscripts, we merge them to get it published in the desired format.</p>
                                          </div>

                                   </div>
                            </div>
                     </div>


                     {/* MargaertSlides Content */}

                     <div className="margaretSlide">
                            <div className="margaretSlideText">
                                   <p><span>Margaret Atwood said,</span> "You're never going to kill storytelling because it's built-in the human plan. We come with it."</p>
                                   <p>So, hurry up and tell your story to the world through us!</p>
                                   <button>Get a Quote</button>
                                   <button>Live Chat</button>
                            </div>
                            <div className="margaretSlideImg">
                                   <img src={Margaret} alt="" />
                            </div>
                     </div>

                     <RecentCollection />
                     <ContactForm />
                     <Footer />
              </>
       )
}

export default BookMarketing
