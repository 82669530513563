import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import Header from "../Common/Header.jsx"
import { BsChevronDown } from 'react-icons/bs';
import { SiMicroDotBlog } from 'react-icons/si';
import { FaComments } from 'react-icons/fa';
import { AiFillHome } from 'react-icons/ai';
import { RiUserSettingsFill } from 'react-icons/ri';
import firebase from "../../Config/firebase"

function BlogPage() {
       ////////////////////////---------------------Retreiving Data To Firebase Database----------------------////////////////////////


       const [blog, setBlog] = useState({
              blogsList: [],
       })
       useEffect(() => {
              var blogs = [];
              firebase.database().ref("blog").on("value", blogdata => {
                     blogdata.forEach(snap => {
                            var blogCard = snap.val()
                            blogCard['key'] = snap.key;
                            blogs.push(blogCard)
                     })
              });
              setBlog({ blogsList: blogs })

       }, [])


       ////////////////////////---------------------Delete Blog Card From Firebase Database----------------------////////////////////////

       const handleDelete = (keyfind) => {


              firebase.database().ref("blog").child(keyfind).remove().then(
                     alert("Blog Card Successfully Removed")
              )
              setBlog(blog)

       }


       return (
              <>
                     <Header />
                     <div className="ServiceContent">
                            <div className="SideMenu">
                                   <ul>
                                          <p>Navigation</p>
                                          <li>
                                                 <Link className="adminNav" to="/admin/dashboard"><AiFillHome className="menuListIcons" />Home</Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/services">
                                                        <RiUserSettingsFill className="menuListIcons" />Services
                                                 </Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/blogs"><SiMicroDotBlog className="menuListIcons" />Blog</Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/testimonials"><FaComments className="menuListIcons" />Testimonials</Link>
                                          </li>
                                   </ul>
                            </div>
                            <div className="ServicePage">
                                   <h1 className="topHeading">Blogs</h1>
                                   <div className="servicePageContent">
                                          <Link to="/admin/add-blogs" className="addBtn">Add Blog</Link>

                                          <div className="inputFields MSIF col-md-12">
                                                 <table className="table" id="services">
                                                        <th className="col-md-3">Name</th>
                                                        <th className="col-md-5">Description</th>
                                                        <th className="col-md-2">Service Name</th>
                                                        <th className="col-md-1">Edit</th>
                                                        <th className="col-md-1">Delete</th>
                                                        {
                                                               blog.blogsList.map((item, index) => {
                                                                      return (
                                                                             <tr key={index}>
                                                                                    <td>{item.name.substr(0, 25)}...</td>
                                                                                    <td>{item.description.substr(0, 140)}...</td>
                                                                                    <td>{item.serviceName}</td>
                                                                                    <td>
                                                                                           <Link className="textStyles" to={`/admin/edit-blogs/${item.key}`}>
                                                                                                  Edit
                                                                                           </Link>
                                                                                    </td>
                                                                                    <td>
                                                                                           <Link className="textStyles" onClick={() => handleDelete(item.key)}>
                                                                                                  Delete
                                                                                           </Link>
                                                                                    </td>
                                                                             </tr>
                                                                      )
                                                               })
                                                        }
                                                 </table>
                                          </div>
                                   </div>
                            </div>
                     </div>
              </>
       )
}

export default BlogPage
