import React, { useState, useEffect } from 'react'
import LogoImg from "../../Images/BookMarketeersLogo.png"
import { BiChevronDown } from 'react-icons/bi';
import { Link } from "react-router-dom"
import "../../StyleSheets/Header/Header.css"
import firebase from "../../Config/firebase"
import { CgMenu } from 'react-icons/cg';
function Header() {


       const [menu, setMenu] = useState(false)

       const [service, setService] = useState({
              serviceList: [],
       })
       useEffect(() => {
              var services = [];
              firebase.database().ref("service").on("value", data => {
                     data.forEach(snap => {
                            var serList = snap.val();
                            serList['key'] = snap.key;
                            services.push(serList)
                     })
              });
              setService({ serviceList: services })
       }, [])


       return (
              <div>
                     <div className="header">
                            <div className="headerContent">
                                   <div className="LogoSite">
                                          <Link className="dropdownItems" to="/"><img src={LogoImg} className='Logo' alt="" /></Link>
                                   </div>
                                   <div className="MenuSite">
                                          <div className={menu ? "responsiceMenu" : "menu"}>
                                                 <ul>
                                                        <li className="HeaderMenu"><Link className="ListNav" to="/">Home</Link></li>
                                                        <li className="HeaderMenu"><Link className="ListNav" to="#">
                                                               Services </Link><BiChevronDown />
                                                               <ul>

                                                                      {
                                                                             service.serviceList.map((item, index) => {
                                                                                    // console.log(item)
                                                                                    return (
                                                                                           <Link key={index} className="dropdownItems" to={`/service/${item.key}`}><li>{item.pageName}</li></Link>
                                                                                           // <li key={index} className="dropdownItems"><Link to={`/service/${item.pageURL}`}>{item.pageName}</Link></li>
                                                                                    )
                                                                             })
                                                                      }
                                                               </ul>
                                                        </li>
                                                        <li className="HeaderMenu"><Link className="ListNav" to="/blogs" >Blogs</Link></li>
                                                        <li className="HeaderMenu"><Link className="ListNav" to="/about-us">About Us</Link></li>
                                                        <li className="HeaderMenu"><Link className="ListNav" to="/faqs">FAQs</Link></li>
                                                        <li className="HeaderMenu"><Link className="ListNav" to="/contact-us">Contact Us</Link></li>
                                                 </ul>
                                          </div>
                                          {/* <CgMenu className="menuIcon" /> */}
                                          <CgMenu className="menuIcon" onClick={() => setMenu(!menu)} />
                                   </div>
                            </div>
                     </div>
              </div>
       )
}

export default Header