import React, { useState, useEffect } from 'react'
import Carousel from "react-elastic-carousel"
import Item from "./Item"
import "../../StyleSheets/Testimonial/Testimonial.css"
import profile1 from "../../Images/testimonialProfile1.jpg"
import star from "../../Images/star.png"
import firebase from 'firebase'
function Testimonial() {

       const [testimonial, setTestimonial] = useState({
              testimonialList: [],
       })
       useEffect(() => {
              var testimonials = [];
              firebase.database().ref("testimonials").on("value", data => {
                     data.forEach(snap => {
                            var TestimonialsList = snap.val();
                            TestimonialsList['key'] = snap.key;
                            testimonials.push(TestimonialsList)
                     })
              });
              setTestimonial({ testimonialList: testimonials })
       }, [])

       return (
              <>
                     <Carousel>
                            <div className="testiContent col-md-4">
                                   {
                                          testimonial.testimonialList.map((item, index) => {
                                                 console.log(item)
                                                 return (
                                                        <>
                                                               {/* Header */}
                                                               <div className="HeadTesti col-md-12">
                                                                      <div className="img col-md-2">
                                                                             <img src={item.personImage} alt="" />
                                                                      </div>
                                                                      <div className="name col-md-9">
                                                                             <p className="profileName">{item.tesName}</p>
                                                                      </div>
                                                                      <div className="star col-md-1">
                                                                             <img src={star} alt="" />
                                                                      </div>
                                                               </div>
                                                               {/* Stars */}
                                                               <div className="stars col-md-12">
                                                                      <img src={star} className="fivestar" alt="" />
                                                                      <img src={star} className="fivestar" alt="" />
                                                                      <img src={star} className="fivestar" alt="" />
                                                                      <img src={star} className="fivestar" alt="" />
                                                                      <img src={star} className="fivestar" alt="" />
                                                               </div>
                                                               <div className="HeadTesti">
                                                                      <p className="reviewText">
                                                                             {item.tesReview}
                                                                      </p>
                                                               </div>

                                                        </>
                                                 )
                                          })
                                   }
                            </div>
                     </Carousel>
              </>
       )
}

export default Testimonial
