// import React from 'react'
// import "../../StyleSheets/RecentCollection/RecentCollection.css"
// import collectionImage1 from "../../Images/collectionImage1.jpg"
// import collectionImage2 from "../../Images/collectionImage2.jpg"
// import collectionImage3 from "../../Images/collectionImage3.jpg"
// import collectionImage4 from "../../Images/collectionImage4.jpg"
// import collectionImage5 from "../../Images/collectionImage5.jpg"



// function RecentCollection() {
//        return (
//               <div className="Collections">
//                      <div className="recentCollectionContent">
//                             <div className="CollectionHeader">
//                                    <h1>Our Recent Collections</h1>
//                                    <p>Our recent work done by our unique talent pool with various skillsets has made us specialize in several genres for catering to ghostwriting purposes of the clients belonging to various industries.</p>
//                             </div>
//                             <div className="CollectionBody">
//                                    <div className="imgContainer">
//                                           <div className="Image">
//                                                  <img src={collectionImage1} className="collectionImage" alt="" />
//                                           </div>
//                                           <div className="Image">
//                                                  <img src={collectionImage2} className="collectionImage" alt="" />
//                                           </div>
//                                           <div className="Image">
//                                                  <img src={collectionImage3} className="collectionImage" alt="" />
//                                           </div>
//                                           <div className="Image">
//                                                  <img src={collectionImage4} className="collectionImage" alt="" />
//                                           </div>
//                                           <div className="Image">
//                                                  <img src={collectionImage5} className="collectionImage" alt="" />
//                                           </div>
//                                    </div>
//                             </div>
//                      </div>
//               </div>
//        )
// }

// export default RecentCollection



import React from 'react'
import "../../StyleSheets/RecentCollection/RecentCollection.css"
import collectionImage1 from "../../Images/collectionImage1.jpg"
import collectionImage2 from "../../Images/collectionImage2.jpg"
import collectionImage3 from "../../Images/collectionImage3.jpg"
import collectionImage4 from "../../Images/collectionImage4.jpg"
import collectionImage5 from "../../Images/collectionImage5.jpg"



function RecentCollection() {
       return (
              <div className="Collections">
                     <div className="recentCollectionContent container">
                            <div className="CollectionHeader">
                                   <h1>Our Recent Collections</h1>
                                   <p>Our recent work done by our unique talent pool with various skillsets has made us specialize in several genres for catering to ghostwriting purposes of the clients belonging to various industries.</p>
                            </div>
                            <div className="CollectionBody">
                                   <div className="imgContainer">
                                          <div className="Image col p-1 p-sm-4">
                                                 <img src={collectionImage1} className="collectionImage" alt="" />
                                          </div>
                                          <div className="Image col p-1 p-sm-4">
                                                 <img src={collectionImage2} className="collectionImage" alt="" />
                                          </div>
                                          <div className="Image col p-1 p-sm-4">
                                                 <img src={collectionImage3} className="collectionImage" alt="" />
                                          </div>
                                          <div className="Image col p-1 p-sm-4">
                                                 <img src={collectionImage4} className="collectionImage" alt="" />
                                          </div>
                                          <div className="Image col p-1 p-sm-4">
                                                 <img src={collectionImage5} className="collectionImage" alt="" />
                                          </div>
                                   </div>
                            </div>
                     </div>
              </div>
       )
}

export default RecentCollection
