import React from 'react'
import { CgArrowLongRight } from 'react-icons/cg';
import "../../StyleSheets/Faq/Faq.css"
import Faqs from "./FaqS.json"
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

function Faq() {

       return (
              <>
                     <Header />

                     <div className="FaqPage">
                            <div className="TopUp">
                                   <div className="TopUpHeading">
                                          <h1>FAQs</h1>
                                   </div>
                                   <div className="IndicatoeBtn">
                                          <p>Home</p>

                                          <CgArrowLongRight className="rightArrow" />

                                          <p>FAQs</p>
                                   </div>
                            </div>

                            {/* FAQ Content */}

                            <div className="faqContent">
                                   <div className="leftForm col-md-4">
                                          <h3>Ask a Question</h3>
                                          <input type="text" className="askInputs" placeholder="Full name" /><br />
                                          <input type="text" className="askInputs" placeholder="Email address" /><br />
                                          <input type="text" className="askInputs" placeholder="Phone number" /><br />
                                          <input type="text" className="askInputs" placeholder="Subject" /><br />
                                          <textarea name="" className="askInputs" id="" cols="43" rows="4" placeholder="Your message"></textarea><br />
                                          <button className="AskBtn">Ask a question</button>
                                   </div>
                                   <div className="RightQuestions col-md-5">
                                          <h4>General Questions</h4>
                                          {
                                                 Faqs.faq.map((item, index) => {
                                                        return (
                                                               < div className="faqDiv" key={index} >
                                                                      <p className="Ques">{item.Q}</p>
                                                                      <p className="Ans">{item.A}</p>
                                                               </div >
                                                        )
                                                 })
                                          }
                                   </div>
                            </div>
                     </div>
                     <Footer />

              </>
       )
}

export default Faq
