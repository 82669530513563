import React from 'react'
import Header from "./Header/Header.jsx"
import Slider from "./Slider/Slider.jsx"
import MidSection from "./Mid/midSection"
import AnimatedSlider from "./animatedSlider/animatedSlider"
import Process from "./OurProcess/Process.jsx"
import HiringSolution from "./HiringSolution/HireSolution.jsx"
import RecentCollection from "./RecentCollection/RecentCollection.jsx"
import Categories from "./Categories/Categories.jsx"
import ContactForm from "./ContactForm/ContactForm.jsx"
import Footer from "./Footer/Footer.jsx"
import 'bootstrap/dist/css/bootstrap.min.css';
// import Master from '../components/Master'
import content from '../assets/data.json'

function Home() {
       return (
              <>
                     <Header />
                     <Slider content={content} />
                     <MidSection />
                     <AnimatedSlider />
                     <Process />
                     <HiringSolution />
                     <RecentCollection />
                     <Categories />
                     <ContactForm />
                     <Footer />
              </>
       )
}

export default Home;