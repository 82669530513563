import React from 'react'
import "../../StyleSheets/animatedSlider/animateSlider.css"
import AnimatedVideo from "../../Videos/Animated_Video.m4v"

function AnimatedSlider() {
       return (
              <div className='animatedSection '>
                     <div className="sectionContent container">
                            <video
                                   autoPlay
                                   loop
                                   muted
                                   style={{
                                          padding: "50px 20px",
                                          margin: "0",
                                   }}
                                   className="col-sm-6"
                            >
                                   <source src={AnimatedVideo} type="video/mp4" />
                            </video>
                            {/* <div className="rightText">
                                   <h1>Let us take it to the next step for your book.</h1>
                                   <p>We help to bring life to your fictional, historical, or any concept in the form of a book that is dreamily written and published. Our team of well seasoned and committed Bookmarketeers is on board who specialize in a vast number of genres. Our great writers ensure that the material is faithfully written and well distributed, with a record of producing large volumes of successful publications.</p>
                                   <button>Get a Quote</button>
                                   <button>Live Chat</button>
                            </div> */}

                            <div className="rightText col-sm-6 pt-5 pb-5">
                                   <h1>Let’s take a further step for your books</h1>
                                   <p>We turn the ideas in your mind into reality by giving them suitable and best-possible words and transforming them into a book. We have a team of highly talented, professional, and passionate writers who work on your idea and make sure they write the book exactly the way you want it to be written. Our exemplary team of editors and proofreaders gives your book remarkable quality and our illustrators design incredible book covers. Altogether, they perform their best to make your book a wonderful publication.</p>
                                   <button>Get a Quote</button>
                                   <button>Live Chat</button>
                            </div>
                     </div>
              </div>
       )
}

export default AnimatedSlider;