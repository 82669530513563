import React from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom";

import Home from '../components/Home'
import Blogs from '../components/Pages/Blogs'
import BlogPages from '../components/Pages/BlogPage'
import About from '../components/Pages/About'
import Contact_us from '../components/Pages/Contact_us'
import Faq from '../components/Faq/Faq.jsx';


import BookWriting from '../components/Pages/Services/BookWriting'
import E_Book from '../components/Pages/Services/E-Book';
import BookCover from '../components/Pages/Services/BookCover';
import ProofReading from '../components/Pages/Services/ProofReading';
import PublishingServices from '../components/Pages/Services/PublishingServices';
import BookMarketing from '../components/Pages/Services/BookMarketing';
import Testimonial from '../components/Testimonials/Testimonial';


import Dashboard from '../Backend/Pages/Dashboard';
import Blog from '../Backend/Pages/Blog';
import AddServicePage from '../Backend/Pages/Services/AddServicePage';
import Testimonials from '../Backend/Pages/Testimonials';
import ServicesPage from '../Backend/Pages/Services/ServicesPage';
import EditServicePage from '../Backend/Pages/Services/EditService';
import BlogPage from '../Backend/Pages/BlogPage';
import EditBlog from '../Backend/Pages/EditBlog';
import AddTestimonials from '../Backend/Pages/AddTestimonials';
import EditTestimonials from '../Backend/Pages/EditTestimonials';
import ServicePageDemo from '../components/Pages/Services/ServicePageDemo';


export default function AppRouter() {
       return (
              <Router >
                     <Route exact path="/" component={Home} />
                     <Route exact path="/about-us" component={About} />
                     <Route exact path="/blogs" component={Blogs} />
                     <Route exact path="/blogpage/:index" component={BlogPages} />
                     <Route exact path="/contact-us" component={Contact_us} />
                     <Route exact path="/faqs" component={Faq} />
                     <Route exact path="/testimonial" component={Testimonial} />


                     <Route exact path="/service/:index" component={ServicePageDemo} />
                     {/* <Route exact path="/service/book-writing-service" component={BookWriting} />
                     <Route exact path="/service/e-book-writing-and-publishing" component={E_Book} />
                     <Route exact path="/service/creative-book-cover-designs" component={BookCover} />
                     <Route exact path="/service/proof-reading-and-editing-services" component={ProofReading} />
                     <Route exact path="/service/book-publishing-services" component={PublishingServices} />
                     <Route exact path="/service/book-marketing-services" component={BookMarketing} /> */}



                     <Route exact path="/admin/dashboard" component={Dashboard} />

                     <Route exact path="/admin/blogs" component={BlogPage} />
                     <Route exact path="/admin/add-blogs" component={Blog} />
                     <Route exact path="/admin/edit-blogs/:index" component={EditBlog} />

                     <Route exact path="/admin/testimonials" component={Testimonials} />
                     <Route exact path="/admin/edit-testimonial/:index" component={EditTestimonials} />
                     <Route exact path="/admin/add-testimonials" component={AddTestimonials} />

                     <Route exact path="/admin/services" component={ServicesPage} />
                     <Route exact path="/admin/add-services" component={AddServicePage} />
                     <Route exact path="/admin/edit-service/:index" component={EditServicePage} />
              </Router>
       )
}