import React, { useState, useEffect } from 'react'
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import { Link } from "react-router-dom"
import firebase from "../../Config/firebase"
import { CgArrowLongRight } from 'react-icons/cg';
import "../../StyleSheets/Pages/Blogs.css"


function Blogs() {


       const [blog, setBlog] = useState({
              blogsList: [],
       })
       useEffect(() => {
              var blogs = [];
              firebase.database().ref("blog").on("value", blogdata => {
                     blogdata.forEach(snap => {
                            var blogCard = snap.val()
                            blogCard['key'] = snap.key;
                            blogs.push(blogCard)
                     })
              });
              setBlog({ blogsList: blogs })

       }, [])
       return (
              <>
                     <Header />

                     {/* Page   Indicator   */}


                     <div className="TopUp">
                            <div className="TopUpHeading">
                                   <h1>Blog</h1>
                            </div>
                            <div className="IndicatoeBtn">
                                   <p>Home</p>
                                   <CgArrowLongRight className="rightArrow" />
                                   <p>Blog</p>
                            </div>
                     </div>




                     {/* Blog Cards   */}


                     <div className="BolgCards">
                            <div className="BCContent d-flex">
                                   {
                                          blog.blogsList.map((item, index) => {

                                                 return (
                                                               <div className="col-4 h-100" key={index}>
                                                                      <Link to={`/blogpage/${item.key}`}><img className="iMg" src={item.image} alt="" /></Link>
                                                                      <Link to={`/blogpage/${item.key}`}>
                                                                      <h1 className="blogTitle">
                                                                             {item.name}
                                                                      </h1></Link>
                                                                      <p className="blogdescription">
                                                                             {item.description.substr(0, 350)}...
                                                                      </p>
                                                                      <p className="serviceName">
                                                                             {item.serviceName}
                                                                      </p>
                                                               </div>
                                                 )
                                          })
                                   }
                                   <Footer />

                            </div>
                     </div>
              </>
       )
}

export default Blogs
