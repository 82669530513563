import React, { useState, useEffect } from 'react'
import "../Styles/Testimonials.css"
import { Link } from "react-router-dom";
import Header from "../Common/Header.jsx"
import { SiMicroDotBlog } from 'react-icons/si';
import { FaComments } from 'react-icons/fa';
import { AiFillHome } from 'react-icons/ai';
import { RiUserSettingsFill } from 'react-icons/ri';
import { storage } from "../../Config/firebase"
import firebase from "../../Config/firebase";


function EditTestimonials(props) {
       ////////////////////////---------------------Retreiving Data To Firebase Database----------------------////////////////////////

       const [E_testi, setE_Tesi] = useState("")

       useEffect(() => {
              var testi = {};
              firebase.database().ref(`testimonials/${props.match.params.index}`).on("value", edittesti => {
                     let data = edittesti.val();
                     let keys = Object.keys(data);
                     keys.forEach((key) => {
                            testi[key] = data[key];
                     });
                     setE_Tesi(testi)
              })
       }, [])

       ////////////////////////---------------------Getting Data In States From Input Fields----------------------////////////////////////


       const [nameUpdate, setNameUpdate] = useState()

       const [reviewUpdate, setReviewUpdate] = useState()

       const [updateTestiImage, setUpdateTestiImage] = useState(null)

       const nameUpdateHandler = (e) => {
              setNameUpdate(e.target.value)
       }

       const reviewUpdateHandler = (e) => {
              setReviewUpdate(e.target.value)
       }
       const UpdateTestiImage = (e) => {
              if (e.target.files[0]) {
                     setUpdateTestiImage(e.target.files[0])
              }
       }

       ////////////////////////---------------------Updated Data Upload To Firebase Database----------------------////////////////////////


       var testimonialImgURL = '';

       const updateFunc = async () => {
              if (updateTestiImage == null) {
                     alert("Please Select an Image")
              }
              else {
                     const uploadtask = storage.ref(`testimonialprofileImages/${updateTestiImage.name}`).put(updateTestiImage);
                     uploadtask.on(
                            "state_changed",
                            // snapshot = {}, 
                            error => {
                                   console.log(error)
                            },
                            () => {
                                   storage
                                          .ref("testimonialprofileImages")
                                          .child(updateTestiImage.name)
                                          .getDownloadURL()
                                          .then(url => {
                                                 testimonialImgURL = url
                                          })
                            }
                     )
                     uploadtask.snapshot.ref.getDownloadURL().then((url) => {
                            var imgUrl = url
                            firebase.database().ref(`testimonials/${props.match.params.index}`).set({
                                   tesName: nameUpdate,
                                   tesReview: reviewUpdate,
                                   personImage: imgUrl
                            })
                            alert("Succesfully Uploaded")
                     })
              }

       }
       return (
              <>
                     <Header />
                     <div className="TestimonialContent">
                            <div className="SideMenu">
                                   <ul>
                                          <p>Navigation</p>
                                          <li>
                                                 <Link className="adminNav" to="/admin/dashboard"><AiFillHome className="menuListIcons" />Home</Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/services">
                                                        <RiUserSettingsFill className="menuListIcons" />Services
                                                 </Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/blogs"><SiMicroDotBlog className="menuListIcons" />Blog</Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/testimonials"><FaComments className="menuListIcons" />Testimonials</Link>
                                          </li>
                                   </ul>
                            </div>
                            <div className="Testimonialpage">
                                   <div className="TestimonialPageContent">
                                          <h1 className="topHeading">Edit Testimonials</h1>
                                          <div className="inputFields TSIF col-md-6"><br />
                                                 <label className="label"> Name :</label><input type="text" defaultValue={E_testi.tesName} onChange={nameUpdateHandler} placeholder="Enter name" className="servicefields" /><br />
                                                 <div className="descriptionContainer">
                                                        <label className="label">Review :</label><textarea placeholder="Enter review description" onChange={reviewUpdateHandler} defaultValue={E_testi.tesReview} className="servicefields" id="" cols="20" rows="5"></textarea><br />
                                                 </div>
                                                 <label className="label">Image :</label><input type="file" onChange={UpdateTestiImage} /><br />
                                                 <button className="ServiceUpload">Update</button>
                                          </div>
                                   </div>
                            </div>
                     </div>
              </>
       )
}

export default EditTestimonials
