import React from 'react'
import "../../StyleSheets/OurProcess/process.css"
import ExtensiveResearch from "../../Images/extensive-research.svg"
import AuthenticContent from "../../Images/authentic-content.svg"
import EvaluativeReview from "../../Images/evaluative-review.svg"
import Formatting from "../../Images/formatting.svg"
import PublishingPromotion from "../../Images/publishing-promotion.svg"

function Process() {
       return (
              <div className="Process container">
                     <div className="row">
                                   <div className="firstProcess col-sm-6">
                                          <div className="head">
                                                 <img src={ExtensiveResearch} alt="" />
                                                 <h4>Extensive Research along with outlining of the draft</h4>
                                          </div>
                                          <p>Our staff contacts the client when the order is dropped and addresses every aspect of the plot or premise or whatever the book is about. Then the committee calls a meeting to speak to the team about the proposal. And when he is sure he can create a masterpiece with the concept, the team chooses which writer to give the job or the writer volunteers. The drafting process starts the very next day, and a writer begins to work on your idea.
                                          </p>
                                   </div>
                                   <div className="secondProcess col-sm-6">
                                          <div className="head2">
                                                 <img src={AuthenticContent} alt="" />
                                                 <h4>Authentic Content Writing</h4>
                                          </div>
                                          <p>After you are done approving our extensively researched outline, our storytellers will immediately start writing your first draft involving your idea. As soon as the first chapter is finished, our staff can email the customer and give him a draught of the first chapter. The writer continues writing on the second chapter after the client approves the writing. If there is something that the consumer needs to change, we clearly ask the writer to obey the client's instructions strictly and operate according to their suggestions. Our mission is to give you the best book writing services, and we make sure that we do so.
                                          </p>
                                   </div>
                                   <div className="container">
                                          <div className="row">
                                                 <div className="process3 col-sm-12 col-md-4">
                                                        <div className="heading">
                                                               <img src={EvaluativeReview} alt="" />
                                                               <h4>Editing and Proofreading</h4>
                                                        </div>
                                                        <p>Our highly professional team of editors performs their best to make sure your manuscript is faultless and flawless. They provide on-time services without compromising the quality.
                                                        </p>
                                                 </div>
                                                 <div className="process4 col-sm-12 col-md-4">
                                                        <div className="heading">
                                                               <img src={Formatting} alt="" />
                                                               <h4>Formatting and Designing</h4>
                                                        </div>
                                                        <p>We format it thoroughly by inserting fonts, drawings and integrating the accepted front andAfter the editing of your book is finished, our excellent team of graphic designers and illustrators design beautiful front and back covers. We send them to you to ask for your approval and if you’re happy, we add them to your book.
                                                        </p>
                                                 </div>
                                                 <div className="process5 col-sm-12 col-md-4">
                                                        <div className="heading">
                                                               <img src={PublishingPromotion} alt="" />
                                                               <h4>Publishing and Promotions</h4>
                                                        </div>
                                                        <p>We’ll send you the final draft after completing all the previous steps. After your approval, we plan a printing format for your book and print it accordingly. We work upon our marketing strategies and conduct promotions for your book.
                                                        </p>
                                                 </div>
                                          </div>
                                   </div>
                     </div>

              </div>
       )
}

export default Process
