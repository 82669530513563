import React, { useState } from 'react'
import "../Styles/blog.css"
import { Link } from "react-router-dom";
import Header from "../Common/Header.jsx"
import { BsChevronDown } from 'react-icons/bs';
import { SiMicroDotBlog } from 'react-icons/si';
import { FaComments } from 'react-icons/fa';
import { AiFillHome } from 'react-icons/ai';
import { RiUserSettingsFill } from 'react-icons/ri';
import { storage } from "../../Config/firebase"
import firebase from "../../Config/firebase"

function Blog() {

       ////////////////////////---------------------Saving Data In States----------------------////////////////////////


       const [blogName, setBlogName] = useState()
       const [blogDecription, setBlogDecription] = useState()
       const [serviceName, setServiceName] = useState()
       const [slug, setSlug] = useState()
       const [blogImage, setBlogImage] = useState(null)

       ////////////////////////---------------------Getting Data From Input Fields For Blogs----------------------////////////////////////

       const BlogTitleHandle = (e) => {
              setBlogName(e.target.value)
       }
       const SlugHandle = (e) => {
              setSlug(e.target.value)
       }

       const BlogDesHandle = (e) => {
              setBlogDecription(e.target.value)
       }

       const ServiceNameHandle = (e) => {
              setServiceName(e.target.value)
       }

       const BlogImageHandle = (e) => {
              if (e.target.files[0]) {
                     setBlogImage(e.target.files[0])
                     console.log(e.target.files[0])
              }
       }



       ////////////////////////---------------------Uploading Data On Firebase----------------------////////////////////////

       var blogImgURL = '';

       const upload = async () => {
              if (blogImage == null) {
                     alert("Please Select an Image")
              }
              else {
                     const uploadtask = storage.ref(`blogImage/${blogImage.name}`).put(blogImage);
                     uploadtask.on(
                            "state_changed",
                            // snapshot = {}, 
                            error => {
                                   console.log(error)
                            },
                            () => {
                                   storage
                                          .ref("blogImage")
                                          .child(blogImage.name)
                                          .getDownloadURL()
                                          .then(url => {
                                                 blogImgURL = url
                                          })
                            }
                     )
                     uploadtask.snapshot.ref.getDownloadURL().then((url) => {
                            var imgUrl = url
                            console.log(url)

                            firebase.database().ref(`blog/${slug}`).push({
                                   name: blogName,
                                   description: blogDecription,
                                   serviceName: serviceName,
                                   image: imgUrl,
                            })

                            alert("Succesfully Uploaded")
                     })
              }

       }



       return (
              <>
                     <Header />
                     <div className="blogContent">
                            <div className="SideMenu">
                                   <ul className="bolgSide">
                                          <p>Navigation</p>
                                          <li>
                                                 <Link className="adminNav" to="/admin/dashboard"><AiFillHome className="menuListIcons" />Home</Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/services">
                                                        <RiUserSettingsFill className="menuListIcons" />Services
                                                 </Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/blogs"><SiMicroDotBlog className="menuListIcons" />Blog</Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/testimonials"><FaComments className="menuListIcons" />Testimonials</Link>
                                          </li>
                                   </ul>
                            </div>
                            <div className="blogPage">
                                   <div className="blogpageContent">
                                          <h1 className="topHeading">Add Blog</h1>
                                          <div className="inputFields col-md-12"><br />
                                                 <label className="label">Title Name</label><input type="text" onChange={BlogTitleHandle} placeholder="Title Heading" className="fields" /><br />
                                                 <label className="label">Slug</label><input type="text" onChange={SlugHandle} placeholder="Enter slug" className="fields" /><br />
                                                 <label className="label">Service Name</label><input type="text" onChange={ServiceNameHandle} placeholder="Enter Service name" className="fields" /><br />
                                                 <div className="descriptionContainer">
                                                        <label className="label">Description</label><textarea name="" placeholder="Description" onChange={BlogDesHandle} className="fields" id="" cols="30" rows="5"></textarea><br />
                                                 </div>
                                                 <label className="label">Image</label><input type="file" onChange={BlogImageHandle} placeholder="Image" /><br />
                                                 <button className="blogUpload" onClick={upload}>Add</button>
                                          </div>
                                   </div>
                            </div>
                     </div>
              </>
       )
}

export default Blog