import React from 'react'
import "../../StyleSheets/Categories/Categories.css"
import CatigoriesSvg1 from "../../Images/CatigoriesSvg1.svg"
import CatigoriesSvg2 from "../../Images/CatigoriesSvg2.svg"
import CatigoriesSvg3 from "../../Images/CatigoriesSvg3.svg"
import CatigoriesSvg4 from "../../Images/CatigoriesSvg4.svg"
import CatigoriesSvg5 from "../../Images/CatigoriesSvg5.svg"
import CatigoriesSvg6 from "../../Images/CatigoriesSvg6.svg"
import CatigoriesSvg7 from "../../Images/CatigoriesSvg7.svg"
import CatigoriesSvg8 from "../../Images/CatigoriesSvg8.svg"
import CatigoriesSvg9 from "../../Images/CatigoriesSvg9.svg"
import CatigoriesSvg10 from "../../Images/CatigoriesSvg10.svg"
import CatigoriesSvg11 from "../../Images/CatigoriesSvg11.svg"
import CatigoriesSvg12 from "../../Images/CatigoriesSvg12.svg"



function Categories() {
       return (
              <div className="Categories">
                     <div className="CategorieContent container">
                            <div className="CategorieHeader">
                                   <div className="CategorieHeadContent">
                                          <h1 style={{ textAlign: "center" }}>We Create Masterpieces!</h1>
                                          <p>No matter what’s your desired genre, plot or concept, Dunesoft is here to convert your idea into a masterpiece. From writing, editing, and publishing to sincere recommendations on book pricing— our fantastic team will assist you at every step. Be it fiction or non-fiction, autobiography or memoirs, Dunesoft’s experienced and professional writers can write on kinds of literary projects. Your idea could belong to any domain— medical or business, education or sports, music or fashion, travel or food, we make sure to create outstanding work that catches the readers’ eyes.</p>
                                   </div>
                            </div>
                            <div className="CategorieBody">
                                   <div className="CategorieBox">
                                          <div className="col-sm-6 col-md-3">
                                                 <div className="box">
                                                        <div className="imgSec">
                                                               <img src={CatigoriesSvg1} className="Cat" alt="" />
                                                        </div>
                                                        <div className="imgNameSec">
                                                               <p>Travel and Lifestyle</p>
                                                        </div>
                                                 </div>
                                          </div>

                                          <div className="col-sm-6 col-md-3">

                                                 <div className="box">
                                                        <div className="imgSec">
                                                               <img src={CatigoriesSvg2} className="Cat" alt="" />
                                                        </div>
                                                        <div className="imgNameSec">
                                                               <p>Government and Non-Profits</p>
                                                        </div>
                                                 </div>
                                          </div>

                                          <div className="col-sm-6 col-md-3">
                                                 <div className="box">
                                                        <div className="imgSec">
                                                               <img src={CatigoriesSvg3} className="Cat" alt="" />
                                                        </div>
                                                        <div className="imgNameSec">
                                                               <p>Medical and Healthcare</p>
                                                        </div>
                                                 </div>
                                          </div>

                                          <div className="col-sm-6 col-md-3">
                                                 <div className="box">
                                                        <div className="imgSec">
                                                               <img src={CatigoriesSvg4} className="Cat" alt="" />
                                                        </div>
                                                        <div className="imgNameSec">
                                                               <p>Legal and Attorney</p>
                                                        </div>
                                                 </div>
                                          </div>

                                          <div className="col-sm-6 col-md-3">
                                                 <div className="box">
                                                        <div className="imgSec">
                                                               <img src={CatigoriesSvg5} className="Cat" alt="" />
                                                        </div>
                                                        <div className="imgNameSec">
                                                               <p>Gaming and Fitness</p>
                                                        </div>
                                                 </div>
                                          </div>

                                          <div className="col-sm-6 col-md-3">
                                                 <div className="box">
                                                        <div className="imgSec">
                                                               <img src={CatigoriesSvg6} className="Cat" alt="" />
                                                        </div>
                                                        <div className="imgNameSec">
                                                               <p>Fashion And Entertainment</p>
                                                        </div>
                                                 </div>
                                          </div>

                                          <div className="col-sm-6 col-md-3">
                                                 <div className="box">
                                                        <div className="imgSec">
                                                               <img src={CatigoriesSvg7} className="FoodCat" alt="" />
                                                        </div>
                                                        <div className="imgNameSec">
                                                               <p>Food and Beverage</p>
                                                        </div>
                                                 </div>
                                          </div>

                                          <div className="col-sm-6 col-md-3">
                                                 <div className="box">
                                                        <div className="imgSec">
                                                               <img src={CatigoriesSvg8} className="Cat" alt="" />
                                                        </div>
                                                        <div className="imgNameSec">
                                                               <p>Business and Real State</p>
                                                        </div>
                                                 </div>
                                          </div>

                                          <div className="col-sm-6 col-md-3">
                                                 <div className="box">
                                                        <div className="imgSec">
                                                               <img src={CatigoriesSvg9} className="WatchCat" alt="" />
                                                        </div>
                                                        <div className="imgNameSec">
                                                               <p>Sports and Music</p>
                                                        </div>
                                                 </div>
                                          </div>

                                          <div className="col-sm-6 col-md-3">
                                                 <div className="box">
                                                        <div className="imgSec">
                                                               <img src={CatigoriesSvg10} className="Cat" alt="" />
                                                        </div>
                                                        <div className="imgNameSec">
                                                               <p>Finance and HR</p>
                                                        </div>
                                                 </div>
                                          </div>


                                          <div className="col-sm-6 col-md-3">
                                                 <div className="box">
                                                        <div className="imgSec">
                                                               <img src={CatigoriesSvg11} className="Cat" alt="" />
                                                        </div>
                                                        <div className="imgNameSec">
                                                               <p>Education and Day Care</p>
                                                        </div>
                                                 </div>
                                          </div>


                                          <div className="col-sm-6 col-md-3">
                                                 <div className="box">
                                                        <div className="imgSec">
                                                               <img src={CatigoriesSvg12} className="Cat" alt="" />
                                                        </div>
                                                        <div className="imgNameSec">
                                                               <p>Startups and Consultants</p>
                                                        </div>
                                                 </div>
                                          </div>
                                   </div>
                            </div>
                     </div>
              </div>
       )
}

export default Categories
