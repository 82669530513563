import React from 'react'
import "../../StyleSheets/MidSection/MidSection.css"

function MidSection() {
       return (
              <div className="videoSlider">
                     <div className="videoSliderContent container">
                            <div className="textVideoSlider text-center text-sm-left col-sm-6">
                                   <div className="textDiv">
                                          <h1>Do you have problems regarding editing or proofreading your book?</h1>
                                          <p>Great minds write great things but even their work needs refinement by expert editors to take it to the next level of perfection. At Dunesoft, we have a team of experienced editors who can edit, polish, and proofread your manuscript to make it look flawless and perfect. If you’re looking for literary consultancy services, sincere guidance for writing, or just a final few suggestions on your script, you’ve got an all-in-one platform.</p>
                                          <button>Get a Quote</button>
                                          <button>Live Chat</button>
                                   </div>
                            </div>
                            <div className="videoside col-sm-6">
                                   <div className="video">
                                          <iframe width="100%" height="350" src="https://www.youtube.com/embed/EMcVopuMIYA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                   </div>
                            </div>
                     </div>
              </div>
       )
}

export default MidSection
