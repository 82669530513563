import React from 'react'
import "../../StyleSheets/SliderStyle/Slider.css"
import TabletImage from "../../Images/slide1-1.png"
import BrandImg1 from "../../Images/BrandImg1.png"
import BrandImg2 from "../../Images/BrandImg2.png"
import BrandImg3 from "../../Images/BrandImg3.png"
import BrandImg4 from "../../Images/BrandImg4.png"
import BrandImg5 from "../../Images/BrandImg5.png"

import BigBrandImg1 from "../../Images/BigBrandImg1.png"
import BigBrandImg2 from "../../Images/BigBrandImg2.png"
import BigBrandImg3 from "../../Images/BigBrandImg3.png"
import BigBrandImg4 from "../../Images/BigBrandImg4.png"
import BigBrandImg5 from "../../Images/BigBrandImg5.png"

function Slider({ content }) {
       return (
              <div className="mainSlider">
                     <div className="Slider">
                            <div className="SliderContent">
                                   <div className="imgSide col-md-6 text-center mb-4">
                                          <img src={TabletImage} alt="" className="img-fluid" />
                                   </div>
                                   <div className="TextSide col-md-6 mt-5 mb-5">
                                          <div className="textContent text-left">
                                                 <div className="tagLine">
                                                        <p>Get ready to become a successful author now</p>
                                                 </div>
                                                 <div className="HeadingLine">
                                                        <h1>{content.slide_heading}</h1>
                                                 </div>
                                                 <div className="ParagraphLine">
                                                        <p>{content.slide_content}</p>
                                                 </div>
                                                 <div className="ButtonsLine">
                                                        <button>Get a Quote</button>
                                                        <button>Live Chat</button>
                                                 </div>
                                                 <div className="brandLogo">
                                                        <div className="brand">
                                                               <img src={BrandImg1} className="brandLogoImg" alt="" />
                                                        </div>
                                                        <div className="brand">
                                                               <img src={BrandImg2} className="brandLogoImg" alt="" />
                                                        </div>
                                                        <div className="brand">
                                                               <img src={BrandImg3} className="brandLogoImg" alt="" />
                                                        </div>
                                                        <div className="brand">
                                                               <img src={BrandImg4} className="brandLogoImg" alt="" />
                                                        </div>
                                                        <div className="brand">
                                                               <img src={BrandImg5} className="brandLogoImg" alt="" />
                                                        </div>
                                                 </div>
                                          </div>
                                   </div>
                            </div>
                            <div className="Branding">
                                   <div className="brandingContent">
                                          <div className="bigBrands text-center col-xs-3 col-sm col">
                                                 <img src={BigBrandImg1} className="bigBrandLogoImg img-fluid" alt="" />
                                          </div>
                                          <div className="bigBrands text-center col-xs-3 col-sm col">
                                                 <img src={BigBrandImg2} className="bigBrandLogoImg img-fluid" alt="" />
                                          </div>
                                          <div className="bigBrands text-center col-xs-3 col-sm col">
                                                 <img src={BigBrandImg3} className="bigBrandLogoImg img-fluid" alt="" />
                                          </div>
                                          <div className="col-12 d-block d-sm-none"></div>
                                          <div className="bigBrands text-center col-6 col-sm">
                                                 <img src={BigBrandImg4} className="bigBrandLogoImg img-fluid" alt="" />
                                          </div>
                                          <div className="bigBrands text-center col-6 col-sm">
                                                 <img src={BigBrandImg5} className="bigBrandLogoImg img-fluid" alt="" />
                                          </div>
                                   </div>
                            </div>
                     </div >
              </div>
       )
}

export default Slider


