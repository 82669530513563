import React from 'react'
import "./Header.css"
// import { FiMenu } from 'react-icons/fi';


function AdminHeader() {
       return (
              <>
                     <div className="adminHeader">
                            <div className="adminContent">
                                   <div className="adminHeaderLeft">
                                          {/* <FiMenu /> */}
                                          <h2>Bookmarketeers</h2>
                                   </div>
                                   <div className="adminHeaderRight">
                                          <h3>Administrator</h3>
                                   </div>
                            </div>
                     </div>
              </>
       )
}

export default AdminHeader
