import React, { useState, useEffect } from 'react'
import "../Styles/Testimonials.css"
import { Link } from "react-router-dom";
import Header from "../Common/Header.jsx"
import { BsChevronDown } from 'react-icons/bs';
import { SiMicroDotBlog } from 'react-icons/si';
import { FaComments } from 'react-icons/fa';
import { AiFillHome } from 'react-icons/ai';
import { RiUserSettingsFill } from 'react-icons/ri';
import firebase from "../../Config/firebase"

function Testimonials() {

       const [testimonial, setTestimonial] = useState({
              testimonialList: [],
       })
       useEffect(() => {
              var testimonials = [];
              firebase.database().ref("testimonials").on("value", data => {
                     data.forEach(snap => {
                            var TestimonialsList = snap.val();
                            TestimonialsList['key'] = snap.key;
                            testimonials.push(TestimonialsList)
                     })
              });
              setTestimonial({ testimonialList: testimonials })
       }, [])



       ////////////////////////---------------------Delete Blog Card From Firebase Database----------------------////////////////////////

       const handleDelete = (keyfind) => {


              firebase.database().ref("testimonials").child(keyfind).remove().then(
                     alert("Testimonials Card Successfully Removed")
              )
              setTestimonial(testimonial)

       }



       return (
              <>
                     <Header />
                     <div className="ServiceContent">
                            <div className="SideMenu">
                                   <ul>
                                          <p>Navigation</p>
                                          <li>
                                                 <Link className="adminNav" to="/admin/dashboard"><AiFillHome className="menuListIcons" />Home</Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/services">
                                                        <RiUserSettingsFill className="menuListIcons" />Services
                                                 </Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/blogs"><SiMicroDotBlog className="menuListIcons" />Blog</Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/testimonials"><FaComments className="menuListIcons" />Testimonials</Link>
                                          </li>
                                   </ul>
                            </div>
                            <div className="ServicePage">
                                   <h1 className="topHeading">Testimonials </h1>
                                   <div className="servicePageContent">
                                          <Link to="/admin/add-testimonials" className="addBtn">Add Testimonial</Link>
                                          <div className="inputFields MSIF col-md-12">
                                                 <table className="table" id="services">
                                                        <th className="col-md-3">Name</th>
                                                        <th className="col-md-5">Description</th>
                                                        <th className="col-md-1">Image Slug</th>
                                                        <th className="col-md-1">Edit</th>
                                                        <th className="col-md-1">Delete</th>
                                                        {
                                                               testimonial.testimonialList.map((item, index) => {
                                                                      return (
                                                                             <tr key={index}>
                                                                                    <td>{item.tesName}</td>
                                                                                    <td>{item.tesReview.substr(0, 40)}...</td>
                                                                                    <td>{item.personImage.substr(0, 40)}...</td>
                                                                                    <td>
                                                                                           <Link className="textStyles" to={`/admin/edit-testimonial/${item.key}`}>
                                                                                                  Edit
                                                                                           </Link>
                                                                                    </td>
                                                                                    <td>
                                                                                           <Link className="textStyles" onClick={() => handleDelete(item.key)}>
                                                                                                  Delete
                                                                                           </Link>
                                                                                    </td>
                                                                             </tr>
                                                                      )
                                                               })
                                                        }
                                                 </table>
                                          </div>
                                   </div>
                            </div>
                     </div>
              </>
       )
}

export default Testimonials
