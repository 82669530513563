import React from 'react'
import AppRouter from './Router/Router';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <AppRouter />
  );
}

export default App;