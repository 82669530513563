import React from 'react'
import "../../StyleSheets/ContactForm/ContactForm.css"
import { BiPhone } from 'react-icons/bi';
import { FaTelegramPlane } from 'react-icons/fa';
import { GoLocation } from 'react-icons/go';


function ContactForm() {
       return (
           <div className="ContactForm">
                  <div className="ContactFormContent container">
                         <div className="formLeft col-md-5">
                                <h1>Get in touch</h1>
                                <p><BiPhone className="contactIcon" />+1-307-219-5194</p>
                                <p><FaTelegramPlane className="contactIcon" />info@book-marketers.com</p>
                                <p><GoLocation className="contactIcon" />3811 Ditmars Blvd #2094 Astoria,
                                       NY 11105 USA</p>
                         </div>
                         <div className="formRight col-md-7">
                                <div className="formContent">
                                       <div className="Datainputs m-sm-4">
                                              <input type="text" className="infoInputs col-6" placeholder="First Name" />
                                              <input type="text" className="infoInputs col-6" placeholder="Last Name " />
                                              <input type="text" className="infoInputs col-6" placeholder="Contact Number" />
                                              <input type="text" className="infoInputs col-6" placeholder="Email Address " />
                                              <input type="text" className="subject" placeholder="Subject" />
                                              <textarea className="commentArea" cols="30" rows="6" placeholder="Your message"></textarea>
                                              <button>Send Message</button>
                                       </div>
                                </div>
                         </div>
                  </div>
           </div>

       )
}

export default ContactForm
