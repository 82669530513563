import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Header from "../Common/Header.jsx"
import { SiMicroDotBlog } from 'react-icons/si';
import { FaComments } from 'react-icons/fa';
import { AiFillHome } from 'react-icons/ai';
import { RiUserSettingsFill } from 'react-icons/ri';
import { storage } from "../../Config/firebase"
import firebase from "../../Config/firebase"

function EditBlog(props) {
       ////////////////////////---------------------Retreiving Data To Firebase Database----------------------////////////////////////

       const [E_blog, setE_Blog] = useState("")

       useEffect(() => {
              var blogs = {};
              firebase.database().ref(`blog/${props.match.params.index}`).on("value", editblog => {
                     let data = editblog.val();
                     let keys = Object.keys(data);
                     keys.forEach((key) => {
                            blogs[key] = data[key];
                     });
                     setE_Blog(blogs)
              })
       }, [])

       ////////////////////////---------------------Getting Data In States From Input Fields----------------------////////////////////////


       const [nameUpdate, setNameUpdate] = useState()

       const [serviceNameUpdate, setServiceNameUpdate] = useState()

       const [desUpdate, setDesUpdate] = useState()

       const [updateBlogImage, setUpdateBlogImage] = useState(null)

       const nameUpdateHandler = (e) => {
              setNameUpdate(e.target.value)
       }
       const serviceNameHandler = (e) => {
              setServiceNameUpdate(e.target.value)
       }

       const descriptionUpdateHandler = (e) => {
              setDesUpdate(e.target.value)
       }
       const UpdateBlogImage = (e) => {
              if (e.target.files[0]) {
                     setUpdateBlogImage(e.target.files[0])
              }
       }

       ////////////////////////---------------------Updated Data Upload To Firebase Database----------------------////////////////////////


       var blogImgURL = '';

       const updateFunc = async () => {
              if (updateBlogImage == null) {
                     alert("Please Select an Image")
              }
              else {
                     const uploadtask = storage.ref(`blogImage/${updateBlogImage.name}`).put(updateBlogImage);
                     uploadtask.on(
                            "state_changed",
                            // snapshot = {}, 
                            error => {
                                   console.log(error)
                            },
                            () => {
                                   storage
                                          .ref("blogImage")
                                          .child(updateBlogImage.name)
                                          .getDownloadURL()
                                          .then(url => {
                                                 blogImgURL = url
                                          })
                            }
                     )
                     uploadtask.snapshot.ref.getDownloadURL().then((url) => {
                            var imgUrl = url
                            console.log(url)

                            firebase.database().ref(`blog/${props.match.params.index}`).set({
                                   name: nameUpdate,
                                   serviceName: serviceNameUpdate,
                                   description: desUpdate,
                                   image: imgUrl
                            })
                            alert("Succesfully Uploaded")
                     })
              }

       }

       return (
              <>
                     <Header />
                     <div className={`blogContent ${props.match.params.index}`} >
                            <div className="SideMenu">
                                   <ul>
                                          <p>Navigation</p>
                                          <li>
                                                 <Link className="adminNav" to="/admin/dashboard"><AiFillHome className="menuListIcons" />Home</Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/services">
                                                        <RiUserSettingsFill className="menuListIcons" />Services
                                                 </Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/blogs"><SiMicroDotBlog className="menuListIcons" />Blog</Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/testimonials"><FaComments className="menuListIcons" />Testimonials</Link>
                                          </li>
                                   </ul>
                            </div>
                            <div className="blogPage">
                                   <div className="blogpageContent">
                                          <h1 className="topHeading">Edit Blog</h1>
                                          <div className="inputFields col-md-12"><br />
                                                 <label className="label">Title Name</label><input type="text" defaultValue={E_blog.name} onChange={nameUpdateHandler} placeholder="Title Heading" className="fields" /><br />
                                                 <label className="label">Service Name</label><input type="text" defaultValue={E_blog.serviceName} onChange={serviceNameHandler} placeholder="Enter Service name" className="fields" /><br />
                                                 <div className="descriptionContainer">
                                                        <label className="label">Description</label><textarea name="" defaultValue={E_blog.description} onChange={descriptionUpdateHandler} placeholder="Description" className="fields" id="" cols="30" rows="5"></textarea><br />
                                                 </div>
                                                 <label className="label">Image</label><input type="file" onChange={UpdateBlogImage} placeholder="Image" /><br />
                                                 <button className="blogUpload" onClick={updateFunc}>Update</button>
                                          </div>
                                   </div>
                            </div>
                     </div>
              </>
       )
}

export default EditBlog
