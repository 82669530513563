import React from 'react'
import "../../StyleSheets/Footer/Footer.css"
import FooterImage1 from "../../Images/Footer1.png"
import FooterImage2 from "../../Images/Footer2.png"
import FBIcon1 from "../../Images/FBIcon1.jpg"
import FBIcon2 from "../../Images/FBIcon2.jpg"
import { BiChevronRight } from 'react-icons/bi';

function Footer() {
       return (
              <>
                     <div className="Footer">
                            <div className="FooterContent">
                                   <div className="footerTabs col-12 col-sm-6 col-md-3">
                                          <p>We jot down effective content for books that makes you a worthy author. In collaboration with your requirements, we make sure that our results create hype around your brand.</p>
                                          <div className="brands">
                                                 <div className="brandingIcons1">
                                                        <img src={FooterImage1} alt="" />
                                                 </div>
                                                 <div className="brandingIcons2">
                                                        <img src={FooterImage2} alt="" />
                                                 </div>
                                          </div>
                                   </div>



                                   <div className="footerTabs col-sm-6 col-md-3">
                                          <h3>Quick Links</h3>
                                          <ul>
                                                 <li><BiChevronRight className="Chevron" />About Us</li>
                                                 <li><BiChevronRight className="Chevron" />FAQs</li>
                                                 <li><BiChevronRight className="Chevron" />Privacy Policy</li>
                                                 <li><BiChevronRight className="Chevron" />Terms & Conditions</li>
                                                 <li><BiChevronRight className="Chevron" />Contact Us</li>
                                          </ul>
                                   </div>


                                   <div className="footerTabs col-sm-6 col-md-3">
                                          <h3>Services</h3>
                                          <ul>
                                                 <li><BiChevronRight className="Chevron" />Book Writing Services USA</li>
                                                 <li><BiChevronRight className="Chevron" />E-Book Writing & Publishing</li>
                                                 <li><BiChevronRight className="Chevron" />Book Cover Design</li>
                                                 <li><BiChevronRight className="Chevron" />Book Proof Reading and Editing</li>
                                                 <li><BiChevronRight className="Chevron" />Book Publishing Services</li>
                                                 <li><BiChevronRight className="Chevron" />Book Marketing</li>
                                          </ul>
                                   </div>



                                   <div className="footerTabs col-sm-6 col-md-3">
                                          <h3>Recent Blog Posts</h3>
                                          <div className="FBP">
                                                 <div className="footerblogImg">
                                                        <img src={FBIcon1} alt="" />
                                                 </div>
                                                 <div className="footerblogRT">
                                                        <div className="date">
                                                               <p>June 25, 2021</p>
                                                        </div>
                                                        <div className="TP">
                                                               <p>Things To Consider When Hiring Book Cover Design Services</p>
                                                        </div>
                                                 </div>
                                          </div>



                                          <div className="FBP">
                                                 <div className="footerblogImg">
                                                        <img src={FBIcon2} alt="" />
                                                 </div>
                                                 <div className="footerblogRT">
                                                        <div className="date">
                                                               <p>June 20, 20201</p>
                                                        </div>
                                                        <div className="TP">
                                                               <p>How Book Publishing Services Works: 5 Steps For Newbie Authors</p>
                                                        </div>
                                                 </div>
                                          </div>
                                   </div>
                            </div>
                     </div>
                     <div className="BottomFooterLine">
                            <h5>© 2021 DuneSoft</h5>
                            <p>DuneSoft.com is registered under company registration number </p>
                     </div>
              </>
       )
}

export default Footer;




