import React from 'react'
import "../../StyleSheets/Pages/Contact_us.css"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import ContactForm from "../ContactForm/ContactForm"
import { CgArrowLongRight } from 'react-icons/cg';

function Contact_us() {
       return (
              <div>
                     <Header />

                     <div className="TopUp">
                            <div className="TopUpHeading">
                                   <h1>Contact Us</h1>
                            </div>
                            <div className="IndicatoeBtn">
                                   <p>Home</p>

                                   <CgArrowLongRight className="rightArrow" />

                                   <p>Contact Us</p>
                            </div>
                     </div>
                     <div className="map">
                            <iframe title="ContactMap" className="contactMap" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6042.981324139362!2d-73.906215!3d40.773226!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25f6f4d90c38b%3A0x5725dcf7eeb4070e!2s38-11%20Ditmars%20Blvd%20%232094%2C%20Astoria%2C%20NY%2011105!5e0!3m2!1sen!2sus!4v1626275322068!5m2!1sen!2sus" ></iframe>
                     </div>
                     <ContactForm />
                     <Footer />

              </div>
       )
}

export default Contact_us
