import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import Header from "../../Common/Header.jsx"
import { SiMicroDotBlog } from 'react-icons/si';
import { FaComments } from 'react-icons/fa';
import { AiFillHome } from 'react-icons/ai';
import { RiUserSettingsFill } from 'react-icons/ri';
import "../../Styles/ServiceStyling/AddService.css"
import firebase from "../../../Config/firebase"

function ServicesPage() {
       const [service, setService] = useState({
              serviceList: [],
       })
       useEffect(() => {
              var services = [];
              firebase.database().ref("service").on("value", data => {
                     data.forEach(snap => {
                            var serList = snap.val();
                            serList['key'] = snap.key;
                            services.push(serList)
                     })
              });
              setService({ serviceList: services })
       }, [])


       const handleDelete = (keyfind) => {
              firebase.database().ref("service").child(keyfind).remove().then(
                     alert("Successfully removed")
              )
              setService(service)
       }

       return (
              <>
                     <Header />
                     <div className="ServiceContent">
                            <div className="SideMenu">
                                   <ul>
                                          <p>Navigation</p>
                                          <li>
                                                 <Link className="adminNav" to="/admin/dashboard"><AiFillHome className="menuListIcons" />Home</Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/services">
                                                        <RiUserSettingsFill className="menuListIcons" />Services
                                                 </Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/blogs"><SiMicroDotBlog className="menuListIcons" />Blog</Link>
                                          </li>
                                          <li>
                                                 <Link className="adminNav" to="/admin/testimonials"><FaComments className="menuListIcons" />Testimonials</Link>
                                          </li>
                                   </ul>
                            </div>
                            <div className="ServicePage">
                                   <h1 className="topHeading">Services </h1>
                                   <div className="servicePageContent">
                                          <Link to="/admin/add-services" className="addBtn">Add Service</Link>

                                          <div className="inputFields MSIF col-md-12">
                                                 <table className="table" id="services">
                                                        <th className="col-md-2">Name</th>
                                                        <th className="col-md-6">Description</th>
                                                        <th className="col-md-2">Slug</th>
                                                        <th className="col-md-1">Edit</th>
                                                        <th className="col-md-1">Delete</th>
                                                        {
                                                               service.serviceList.map((item, index) => {
                                                                      return (
                                                                             <tr key={index}>
                                                                                    <td>{item.pageName}</td>
                                                                                    <td>{item.description.substr(0, 40)}...</td>
                                                                                    <td>{item.pageURL}</td>
                                                                                    <td>
                                                                                           <Link className="textStyles" to={`/admin/edit-service/${item.key}`}>
                                                                                                  Edit
                                                                                           </Link>
                                                                                    </td>
                                                                                    <td>
                                                                                           <Link className="textStyles" onClick={() => handleDelete(item.key)}>
                                                                                                  Delete
                                                                                           </Link>
                                                                                    </td>
                                                                             </tr>
                                                                      )
                                                               })
                                                        }
                                                 </table>
                                          </div>
                                   </div>
                            </div>
                     </div>
              </>
       )
}

export default ServicesPage
